<template>
  <div class="home">
    <section class="home--section-left">
      <a :href="bannerUrl" target="_blank" class="home__link">
        <v-img :src="require(`@/assets/images/banner.png`)" position="top center" max-width="100%" height="100vh">
        </v-img>
      </a>
    </section>
    <section class="home--section-right pa-4 pa-sm-6 pa-lg-8">
      <div class="home__container">
        <header class="home__container__header">
          <div class="home__container__header__left">
            <a href="https://kahwacoffee.com/" target="_blank">
              <img :src="require('@/assets/images/logo-kahwa.svg')" alt="Kahwa" />
            </a>
          </div>
        </header>
        <div class="home__container__main">
          <div class="home__container__main__intro text-center mt-12">
            {{ $t('home.intro') }}
          </div>
          <h1 class="home__container__main__title text-center mt-4">{{ $t('home.title') }}</h1>
          <div class="home__container__main__separator" v-if="$t('home.separator')">
            <span class="home__container__main__separator__label px-4">{{ $t('home.separator') }}</span>
          </div>
          <div class="home__container__main__caption text-center mt-6" v-html="$t('home.caption')"></div>
          <div class="home__container__main__prices text-center mt-6" v-html="$t('home.prices')"></div>

          <div class="home__container__language text-h6 mt-8">
            <div class="home__container__language__label mb-2">{{ $t('home.language.label') }}</div>

            <v-select
              class="home__container__language__label__selector"
              :items="selectableItems"
              v-model="currentShop"
              outlined
              :value="null"
              hide-details
              solo
            >
              <template v-slot:selection="{ item }">
                <span class="d-flex justify-center text-uppercase" style="width: 100%">
                  {{ item.text }}
                </span>
              </template>
            </v-select>
          </div>
          <v-btn
            depressed
            color="secondary"
            tile
            x-large
            height="54"
            :disabled="currentShop === 'coffee00'"
            :href="linkHref"
            class="home__container__main__link mt-16 mb-4"
          >
            {{ $t('home.cta') }}
          </v-btn>
          <div class="home__container__disclaimer text-center" v-html="$t('home.disclaimer')"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: vm => ({
    currentLanguage: vm.$i18n.locale,
    currentShop: 'coffee00',
    items: [
      {
        text: 'English',
        value: 'en',
        website: 'https://kahwacoffee.com/',
      },
    ],
    shopItems: [
      {
        text: '',
        value: 'coffee00',
        href: '',
      },
      {
        text: 'Kahwa Coffee St. Petersburg - USF Campus',
        value: 'kahwa01',
        href: 'https://kahwa.gtr.cx/kahwa01',
      },
      {
        text: 'Kahwa Coffee Tampa - Airport Marriott',
        value: 'kahwa02',
        href: 'https://kahwa.gtr.cx/kahwa02',
      },
      {
        text: 'Kahwa Coffee Tampa - Mercedes',
        value: 'kahwa03',
        href: 'https://kahwa.gtr.cx/kahwa03',
      },
      {
        text: 'Kahwa Coffee St. Petersburg - North',
        value: 'kahwa04',
        href: 'https://kahwa.gtr.cx/kahwa04',
      },
      {
        text: 'Kahwa Coffee St. Petersburg - South',
        value: 'kahwa05',
        href: 'https://kahwa.gtr.cx/kahwa05',
      },
      {
        text: 'Kahwa Coffee St. Petersburg - West',
        value: 'kahwa06',
        href: 'https://kahwa.gtr.cx/kahwa06',
      },
      {
        text: 'Kahwa Coffee St Petersburg - Bayfront',
        value: 'kahwa07',
        href: 'https://kahwa.gtr.cx/kahwa07',
      },
      {
        text: 'Kahwa Coffee Tampa - Element',
        value: 'kahwa08',
        href: 'https://kahwa.gtr.cx/kahwa08',
      },
      {
        text: 'Kahwa Coffee Tampa - Henderson',
        value: 'kahwa09',
        href: 'https://kahwa.gtr.cx/kahwa09',
      },
      {
        text: 'Kahwa Coffee Downtown Sarasota',
        value: 'kahwa10',
        href: 'https://kahwa.gtr.cx/kahwa10',
      },
      {
        text: 'Kahwa Coffee Bradenton - Lakewood Ranch',
        value: 'kahwa11',
        href: 'https://kahwa.gtr.cx/kahwa11',
      },
      {
        text: 'Kahwa Coffee Dunedin',
        value: 'kahwa12',
        href: 'https://kahwa.gtr.cx/kahwa12',
      },
      {
        text: 'Kahwa Coffee St. Petersburg - St. Anthonys',
        value: 'kahwa13',
        href: 'https://kahwa.gtr.cx/kahwa13',
      },
      {
        text: 'Kahwa Coffee St. Petersburg - 4th Street',
        value: 'kahwa14',
        href: 'https://kahwa.gtr.cx/kahwa14',
      },
      {
        text: 'Kahwa Coffee - St Petersburg Southside',
        value: 'kahwa15',
        href: 'https://kahwa.gtr.cx/kahwa15',
      },
      {
        text: 'Kahwa Coffee - Tampa Gandy',
        value: 'kahwa16',
        href: 'https://kahwa.gtr.cx/kahwa16',
      },
      {
        text: 'Kahwa Coffee - Justin',
        value: 'kahwa17',
        href: 'https://kahwa.gtr.cx/kahwa17',
      },
      {
        text: 'Kahwa Coffee - Magnolia',
        value: 'kahwa18',
        href: 'https://kahwa.gtr.cx/kahwa18',
      },
      {
        text: 'Kahwa Coffee - USF Tampa',
        value: 'kahwa19',
        href: 'https://kahwa.gtr.cx/kahwa19',
      },
    ],
  }),
  metaInfo: vm => ({
    title: vm.$t('meta.title'),
    meta: [{ name: 'description', content: vm.$t('meta.description') }],
  }),
  computed: {
    itemByLocale() {
      return this.items.find(item => item.value === this.currentLanguage)
    },
    itemByShop() {
      return this.shopItems.find(shop => shop.value === this.currentShop)
    },
    linkHref() {
      const item = this.itemByShop
      return `${item.href}`
    },
    bannerUrl() {
      const item = this.itemByShop
      return item.website
    },
    selectableItems() {
      const shiftedItems = [...this.shopItems]
      shiftedItems.shift()
      return shiftedItems
    },
  },
  methods: {
    onChange(locale) {
      this.$i18n.locale = locale
    },
    logoByLocale(param) {
      const item = this.itemByLocale
      return item.logo ? item.logo[param] : null
    },
  },
}
</script>

<style lang="scss">
.home {
  display: flex;
  min-height: 100%;

  &--section-left {
    display: none;
    position: fixed;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      display: flex;
      width: calc(100% - 600px);
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      width: calc(100% - 900px);
    }

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      width: calc(100% - 1100px);
    }

    @media (min-width: map-get($grid-breakpoints, 'xxl')) {
      width: calc(100% - 1300px);
    }
  }

  &__link {
    max-width: 100%;
  }

  &--section-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-bg;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      margin-left: calc(100% - 600px);
      width: 600px;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      margin-left: calc(100% - 900px);
      width: 900px;
    }

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      margin-left: calc(100% - 1100px);
      width: 1100px;
    }

    @media (min-width: map-get($grid-breakpoints, 'xxl')) {
      margin-left: calc(100% - 1300px);
      width: 1300px;
    }
  }

  &__logo {
    position: absolute;
    bottom: $gutter-tablet;
    left: $gutter-tablet;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      bottom: $gutter-desktop;
      left: $gutter-desktop;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      justify-content: space-between;
      max-width: 550px;
    }

    &__header {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;

      &__left,
      &__center,
      &__right {
        display: flex;
        position: relative;
        justify-content: center;

        a {
          display: inline-flex;

          img {
            max-width: 100%;
            height: 60px;
          }
        }
      }

      &__center {
        &:before {
          position: absolute;
          top: 50%;
          left: -16px;
          transform: translateY(-50%);
          background-color: rgba($color-text-main, 0.8);
          width: 2px;
          height: 70%;
          content: '';
        }
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-transform: uppercase;

      &__intro {
        text-transform: initial;
        font-size: 1.375rem;
      }

      &__title {
        color: $color-text-accent;
        font-size: 1.875rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 2.5rem;
        }
      }

      &__separator {
        position: relative;
        width: 100%;
        text-align: center;
        color: $color-text-main;
        font-size: 1.5rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.75rem;
        }

        &:before {
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          transform: translateY(-50%);
          background-color: rgba($color-text-main, 0.7);
          height: 2px;
          content: '';
        }

        &__label {
          position: relative;
          background-color: $color-bg;
        }
      }

      &__subtitle {
        text-transform: uppercase;
        color: $color-text-accent;
        font-size: 1.7rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 2.25rem;
        }
      }

      &__caption {
        color: $color-text-main;
        font-size: 1.25rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.75rem;
        }
      }

      &__prices {
        text-transform: initial;
        color: $color-text-alt;

        a {
          color: $color-text-alt !important;
        }
      }

      &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: bold;
      }
    }

    &__language {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__label {
        text-transform: uppercase;
        color: $color-text-main;
        font-size: 1.25rem;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.5rem;
        }
      }
    }
    &__disclaimer {
      font-size: 0.75rem;
    }
  }
}
</style>
